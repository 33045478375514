<template>
    <div>
        <template v-if="link">
            <!-- NOTE: this was previously to account-view-domain but what people usually want to do is edit DNS... if there's any issue with the status we will show alerts in the dns editing activity. -->
            <span><router-link :to="{ name: 'account-edit-dns', params: { accountId: this.$route.params.accountId, domain: name }}">{{ name }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ name }}</span>
        </template>
        <p class="text-caption">{{ status }}</p>
    </div>
</template>

<script>
export default {

    props: {
        // whether the item should link to the account record; a parent component may set this to false if it just wants the user to select an account (and not navigate to it)
        link: {
            type: Boolean,
            default: false,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        name() {
            return this.attr.name;
        },
        status() {
            return this.attr.status;
        },
    },

};
</script>
