<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Service Administration</h1>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5">
            <v-col style="text-align: center" cols="12" sm="6">
                <!-- <template v-if="!isAuthenticatedReady">
                    <h1 class="text-h4 font-weight-light mt-15">Checking authentication status...</h1>
                </template>
                <template v-if="isAuthenticatedReady">
                    <p class="mt-15" v-if="isAuthenticated">
                        <router-link to="/dashboard">Continue to dashboard</router-link>
                    </p>
                    <p class="mt-15" v-if="!isAuthenticated">
                        <router-link to="/login">Login</router-link>
                    </p>
                </template> -->
                <!-- <h1>Service admin</h1>
                 -->
                <v-card tile elevation="4" class="pa-0">
                    <v-toolbar short flat color="white">
                        <v-toolbar-title class="blue--text">C2</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon color="blue" @click="openCreateAccountDialog">
                            <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                        </v-btn> -->
                        <!-- <v-menu offset-y left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="blue" v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                <v-list-item :to="{ name: 'service-admin-account-settings' }">
                                    <v-list-item-title>Settings</v-list-item-title>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu> -->
                    </v-toolbar>
                    <v-card-text class="text-start">
                        <p>
                            <router-link :to="{ name: 'service-admin-c2' }">C2 setup</router-link>
                        </p>
                    </v-card-text>
                </v-card>

                <v-card tile elevation="4" class="pa-0 mt-8">
                    <v-toolbar short flat color="white">
                        <v-toolbar-title class="blue--text">DNS</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon color="blue" @click="openCreateAccountDialog">
                            <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                        </v-btn> -->
                        <!-- <v-menu offset-y left>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="blue" v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                <v-list-item :to="{ name: 'service-admin-account-settings' }">
                                    <v-list-item-title>Settings</v-list-item-title>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu> -->
                    </v-toolbar>
                    <v-card-text class="text-start">
                        <p>
                            <router-link :to="{ name: 'service-admin-dns' }">See complete TinyDNS configuration</router-link>
                        </p>
                    </v-card-text>
                </v-card>

                <AccountList class="mt-8"/>

                <DomainList class="mt-8"/>

                <v-expansion-panels tile class="mt-8">
                    <v-expansion-panel v-if="serviceName && serviceVersion">
                        <v-expansion-panel-header>Service information</v-expansion-panel-header>
                        <v-expansion-panel-content class="text-start grey--text text--darken-2">
                            <p class="text-overline">Service name</p>
                            <p>{{ serviceName }}</p>
                            <p class="text-overline">Service version</p>
                            <p>{{ serviceVersion }}</p>
                            <!-- <p class="text-overline">Login URL Template</p>
                            <p>
                                {{ loginURL }}
                                <v-btn class="blue--text no-print" @click="dialogEditLoginURL = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn>
                            </p>
                            <v-dialog v-model="dialogEditLoginURL" max-width="600">
                                <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Edit default login URL</v-card-text>
                                    <v-row justify="center" class="pt-3">
                                        <v-col cols="12">
                                            <div class="font-weight-light text-body-1">
                                                <p>When users need to login to a realm that does not have a custom login URL template, this is where they will be redirected.</p>
                                                <p>This needs to be coordinated with the Unicorn Springs deployment settings. The template must include the following placeholder:</p>
                                                <ul>
                                                <li>&#123;&#123;realm&#125;&#125; - the realm name</li>
                                                <li>&#123;&#123;token&#125;&#125; - the login token</li>
                                                </ul>
                                                <p>Examples:</p>
                                                <ul>
                                                    <li>https://example.com/login?realm=&#123;&#123;realm&#125;&#125;&amp;token=&#123;&#123;token&#125;&#125;</li>
                                                    <li>https://login.example.com/realm/&#123;&#123;realm&#125;&#125;/login?token=&#123;&#123;token&#125;&#125;</li>
                                                </ul>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-form @submit="editLoginURL" onSubmit="return false;" @keyup.enter.native="editLoginURL" class="mt-5">
                                        <v-text-field
                                            v-model=editableLoginURL
                                            label="Default login URL template"
                                            :rules="urlRules"
                                            outlined
                                        ></v-text-field>
                                        <v-card-actions>
                                            <v-row justify="center">
                                            </v-row>
                                        </v-card-actions>
                                    </v-form>
                                    <v-card-actions>
                                        <v-spacer/>
                                        <v-btn elevation="4" class="blue white--text" @click="editLoginURL" :disabled="!isEditLoginURLFormComplete">
                                            Save
                                        </v-btn>
                                        <v-btn text class="grey--text" @click="dialogEditLoginURL = false">Cancel</v-btn>
                                        <v-spacer/>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog> -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import AccountList from '@/components/service-dashboard/AccountList.vue';
import DomainList from '@/components/service-dashboard/DomainList.vue';

export default {
    components: {
        AccountList,
        DomainList,
    },
    data: () => ({
        serviceName: null,
        serviceVersion: null,
        serviceConfig: null,
        accountList: [],
        // // default login url template
        // dialogEditLoginURL: false,
        // editableLoginURL: null,
        // urlRules: [
        //     (v) => isValidURL(v) || 'Enter a default login URL',
        // ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        // loginURL() {
        //     return this.serviceConfig?.login_url_template ?? 'Undefined';
        // },
        // isEditLoginURLFormComplete() {
        //     return this.editableLoginURL && isValidURL(this.editableLoginURL);
        // },
    },
    watch: {
        // dialogEditLoginURL(value) {
        //     if (value) {
        //         this.editableLoginURL = this.serviceConfig?.login_url_template ?? '';
        //     }
        // },
    },
    methods: {
        init() {
            this.loadServiceVersion();
            this.loadServiceConfig();
            this.loadAccountList();
        },
        async loadServiceVersion() {
            try {
                this.$store.commit('loading', { loadServiceVersion: true });
                const response = await this.$client.main().service.getVersion();
                if (response) {
                    this.serviceName = response.name;
                    this.serviceVersion = response.version;
                }
            } catch (err) {
                console.error('loadServiceVersion failed', err);
            } finally {
                this.$store.commit('loading', { loadServiceVersion: false });
            }
        },
        async loadServiceConfig() {
            try {
                this.$store.commit('loading', { loadServiceConfig: true });
                const result = await this.$client.main().service.getSettingsList(); // TODO: query for specific settings like registration_mode, login_url_template
                console.log(`loadServiceConfig result: ${JSON.stringify(result)}`);
                if (result && Array.isArray(result.list)) {
                    const configMap = {};
                    result.list.forEach((item) => { configMap[item.name] = item.content; });
                    this.serviceConfig = configMap;
                }
            } catch (err) {
                console.error('loadServiceConfig failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load service settings', message });
            } finally {
                this.$store.commit('loading', { loadServiceConfig: false });
            }
        },
        // async editLoginURL() {
        //     try {
        //         this.$store.commit('loading', { editLoginURL: true });
        //         if (this.editableLoginURL && isValidURL(this.editableLoginURL)) {
        //             const isEdited = await this.$client.main().service.editSetting({ name: 'login_url_template', content: this.editableLoginURL });
        //             if (isEdited) {
        //                 this.loadServiceConfig();
        //                 this.$bus.$emit('snackbar', { type: 'success', message: 'Saved login URL template' });
        //             } else {
        //                 this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to set login URL template' });
        //             }
        //         }
        //     } catch (err) {
        //         console.error('editLoginURL failed', err);
        //         this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change login URL template' });
        //     } finally {
        //         this.$store.commit('loading', { editLoginURL: false });
        //         this.dialogEditLoginURL = false;
        //     }
        // },
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const response = await this.$client.main().service.getAccountList();
                if (response?.list) {
                    this.accountList = response.list;
                }
            } catch (err) {
                console.error('loadAccountList failed', err);
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        onSelectAccount({ accountId }) {
            console.log(`selected account ${accountId}`);
        },
    },
    mounted() {
        this.init();
    },
};
</script>
